export function normalOrder(index, tipo, productInfo, ingredients, extra, amount) {
  var order =
  {
    id: productInfo.id, 
    index :index,  
    quantita: amount,
    prodotto: productInfo.name,
    logicalName: productInfo.product,
    tipo: tipo,
    price: productInfo.price,
    stato: false,
    ingredients: {
      lattuga: ingredients[0],
      radicchio: ingredients[1],
      pomodoro: ingredients[2],
      cipolla: ingredients[3],
      cappuccio: ingredients[4],
      salsa_peperoni: ingredients[5],
      salsa_aglio: ingredients[6],
      salsa_piccante: ingredients[7],
    },
    extra:{
      patate: extra[0],
      verdura: extra[1],
      formaggio: extra[2],
      piccante: extra[3],
      salse: extra[4],
      ketchup: extra[5],
      maionese: extra[6]
    }
  }
  return order
}

export function bibitaOrder(index, tipo, productInfo, amount) {
  var order =
  {
    id: productInfo.id,
    stato: false,
    index :index, 
    quantita: amount,
    prodotto: productInfo.name,
    tipo: tipo,
    price: productInfo.price,
    logicalName: productInfo.product
  }
  return order
}

export function menuOrder(index, tipo, productInfo, ingredients, extra, bibita, contorno, amount) {
  var order =
  {
    id: productInfo.id,
    stato: false,
    index :index,
    quantita: amount,
    bibita: bibita,
    contorno: contorno,
    prodotto: productInfo.name,
    tipo: tipo,
    price: productInfo.price,
    logicalName: productInfo.product,
    ingredients: {
      lattuga: ingredients[0],
      radicchio: ingredients[1],
      pomodoro: ingredients[2],
      cipolla: ingredients[3],
      cappuccio: ingredients[4],
      salsa_peperoni: ingredients[5],
      salsa_aglio: ingredients[6],
      salsa_piccante: ingredients[7],
    },
    extra: {
      patate: extra[0],
      verdura: extra[1],
      formaggio: extra[2],
      piccante: extra[3],
      salse: extra[4],
      ketchup: extra[5],
      maionese: extra[6]
    }
  }
  return order
}

export function chickenPatateOrder(index, tipo, productInfo, amount, hasKetchup, hasMaionese) {
  var order =
  {
    id: productInfo.id,
    index :index,
    logicalName: productInfo.product,
    quantita: amount,
    prodotto: productInfo.name,
    tipo: tipo,
    price: productInfo.price,
    ingredients:
    {
      ketchup: hasKetchup,
      maionese: hasMaionese
    }
  }
  return order
}

export function altroOrder(index, tipo, productInfo, amount) {
  var order =
  {
    id: productInfo.id,
    index :index,
    quantita: amount,
    logicalName: productInfo.product,
    prodotto: productInfo.name,
    tipo: tipo,
    price: productInfo.price
    
  }
  return order
} 

export function patatineOrder(index, tipo, productInfo, amount, hasKetchup, hasMaionese) {
  var order =
  {
    id: productInfo.id,
    index :index, 
    quantita: amount,
    prodotto: productInfo.name,
    logicalName: productInfo.product,
    tipo: tipo,
    price: productInfo.price,
    ingredients:
    {
      ketchup: hasKetchup,
      maionese: hasMaionese
    }
  }
  return order
} 

export function contorniOrder(index, tipo, productInfo, amount) {
  var order =
  {
    id: productInfo.id,
    index :index,
    quantita: amount,
    logicalName: productInfo.product,
    prodotto: productInfo.name,
    tipo: tipo,
    price: productInfo.price
  }
  return order
} 
