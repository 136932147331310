import panino from "./../images/panino.png"
import piadina from "./../images/piadina.png"
import piatto from "./../images/piatto.png"
import menuI from "./../images/menuI.png"
import contorno from "./../images/contorno.png"
import bibita from "./../images/bibita.png"
import cevapciciI from "./../images/cevapciciI.png"
import altroI from "./../images/altroI.png"

import paninoKebab from "./../images/paninoKebab.png"
import paninoTavuk from "./../images/paninoTavuk.png"
import paninoVegi from "./../images/paninoVegi.png"
import paninoFalafel from "./../images/paninoFalafel.png"
import hotDog from "./../images/hotDog.png"
import hamburger from "./../images/hamburger.png"

import piadinaKebab from "./../images/piadinaKebab.png"
import piadinaTavuk from "./../images/piadinaTavuk.png"
import piadinaFalafel from "./../images/piadinaFalafel.png"
import piadinaHamburger from "./../images/piadinaHamburger.png"
import vegiPiadina from "./../images/vegiPiadina.png"

import piattoKebab from "./../images/piattoKebab.png"
import piattoTavuk from "./../images/piatoTavuk.png"
import piattoFalafel from "./../images/piattoFalafel.png"
import boxKebab from "./../images/boxKebab.png"

import menuPiadina from "./../images/menuPiadina.png"
import menuPanino from "./../images/menuPanino.png"

import patatine from "./../images/patatine.png"
import nuggetsPollo from "./../images/nuggetsPollo.png"
import falafel from "./../images/falafel.png"
import ketchup from "./../images/ketchup.png"
import maionese from "./../images/maionese.png"
import salsaPiccante from "./../images/salsaPiccante.png"
import salsaPeperoni from "./../images/salsaPeperoni.png"
import salsaAglio from "./../images/salsaAglio.png"

import cevapciciP from "./../images/cevapciciP.png"

import acqua from "./../images/acqua.png"
import redbull from "./../images/redbull.png"
import fanta from "./../images/fanta.png"
import coca from "./../images/coca.png"
import cocaZero from "./../images/cocaZero.png"
import pepsi from "./../images/pepsi.png"
import sprite from "./../images/sprite.png"
import lemon from "./../images/lemon.png"
import tonica from "./../images/tonica.png"
import tePesca from "./../images/tePesca.png"
import tePesca05 from "./../images/tePesca05.png"
import teLimone from "./../images/teLimone.png"
import teLimone05 from "./../images/teLimone05.png"
import ayran from "./../images/ayran.png"
import pepsiBottiglia from "./../images/pepsi-bottiglia.png"
import colaBottiglia from "./../images/coca-cola-bottiglia.png"
import colaZeroBottiglia from "./../images/cola-zero-bottiglia.png"
import golden from "./../images/golden.png"

import vaschettaCarne from "./../images/vaschettaCarne.png"
import nuggetspatate from "./../images/nuggetspatate.png"

import anelli from "./../images/anelli.png"
import mozzarella from "./../images/mozzarella.png"

import baklava from "./../images/baklava.png"
import amarena from "./../images/amarena.png"

export const OrderList = [
  {
    type: 'panini',
    name: 'PANINI',
    image: panino,
    alt: 'panino.png',
  },
  {
    type: 'piadine',
    name: 'PIADINE',
    image: piadina,
    alt: 'piadina.png',
  },
  {
    type: 'piatti',
    name: 'PIATTI',
    image: piatto,
    alt: 'piatto.png',
  },
  {
    type: 'menu',
    name: 'MENÙ',
    image: menuI,
    alt: 'menuI.png',
  },
  {
    type: 'contorni',
    name: 'CONTORNI',
    image: contorno,
    alt: 'contorno.png',
  },
  {
    type: 'cevapcici',
    name: 'CEVAPCICI',
    image: cevapciciI,
    alt: 'cevapcici.png',
  },
  {
    type: 'bibite',
    name: 'BIBITE',
    image: bibita,
    alt: 'bibita.png',
  },
  {
    type: 'altro',
    name: 'DOLCE ',
    image: altroI,
    alt: 'altroI.png',
  },
]

export const MenuListArray = [
  {
    type: 'vitello',
    name: 'VITELLO',
    image: menuI,
    alt: 'menu.png',
  },
  {
    type: 'pollo',
    name: 'POLLO',
    image: menuI,
    alt: 'menu.png',
  },
  {
    type: 'misto',
    name: 'MISTO',
    image: menuI,
    alt: 'menu.png',
  }
]

export const panini = [
  {
    id: "price_1MO6RtFHf4jW5GMcD9ZpvpMx",
    product: 'panino-kebab',
    name: 'Panino Kebab (Vitello)',
    price: '6.00',
    image: paninoKebab,
    alt: 'paninoKebab.png',
    desc: 'Carne di vitello, verdura e salsa'
  },
  {
    id: "price_1MOQCSFHf4jW5GMc51uJTpnk",
    product: 'maxi-panino-kebab',
    name: 'Maxi Panino Kebab (Vitello)',
    price: '8.00',
    image: paninoKebab,
    alt: 'paninoKebab.png',
    desc: 'Carne di vitello, verdura e salsa'
  },
  {
    id: "price_1MOQEBFHf4jW5GMcjMjBbAle",
    product: 'panino-tavuk',
    name: 'Panino Tavuk (Pollo)',
    price: '6.00',
    image: paninoTavuk,
    alt: 'paninoTavuk.png',
    desc: 'Carne di pollo, verdura e salsa'
  },
  {
    id: "price_1MOQFPFHf4jW5GMcP5AB292f",
    product: 'maxi-panino-tavuk',
    name: 'Maxi Panino Tavuk (Pollo)',
    price: '8.00',
    image: paninoTavuk,
    alt: 'paninoTavuk.png',
    desc: 'Carne di pollo, verdura e salsa'
  },
  {
    id: "price_1MOQJGFHf4jW5GMcYEQ63P82",
    product: 'panino-kebab-e-tavuk',
    name: 'Panino Misto Kebab-Tavuk',
    price: '6.00',
    image: paninoKebab,
    alt: 'paninoKebab.png',
    desc: 'Carne di vitello e pollo, verdura e salsa'
  },
  {
    id: "price_1MOQKPFHf4jW5GMcjTclzalo",
    product: 'maxi-panino-kebab-e-tavuk',
    name: 'Maxi Panino Misto Kebab-Tavuk',
    price: '8.00',
    image: paninoKebab,
    alt: 'paninoKebab.png',
    desc: 'Carne di vitello e pollo, verdura e salsa'
  },
  {
    id: "price_1MOQLQFHf4jW5GMc6PJ0fRtj",
    product: 'panino-vegi',
    name: 'Panino Vegi',
    price: '6.00',
    image: paninoVegi,
    alt: 'paninoVegi.png',
    desc: 'Formaggio verdura e salsa'
  },
  {
    id: "price_1MOQN2FHf4jW5GMce61pkV2b",
    product: 'maxi-panino-vegi',
    name: 'Maxi Panino Vegi',
    price: '7.50',
    image: paninoVegi,
    alt: 'paninoVegi.png',
    desc: 'Formaggio verdura e salsa'
  },
  {
    id: "price_1MOR66FHf4jW5GMcn71s65tW",
    product: 'panino-falafel',
    name: 'Panino Falafel',
    price: '6.00',
    image: paninoFalafel,
    alt: 'paninoFalafel.png',
    desc: '4 polpette di ceci con verdura e salsa'
  },
  {
    id: "price_1MOR7JFHf4jW5GMcNmeOe0Cu",
    product: 'maxi-panino-falafel',
    name: 'Maxi Panino Falafel',
    price: '7.50',
    image: paninoFalafel,
    alt: 'paninoFalafel.png',
    desc: '6 polpette di ceci con verdura e salsa'
  },
  
  {
    id: "price_1OfTxSFHf4jW5GMcpUovYp9d",
    product: 'hamburger',
    name: 'Hamburger',
    price: '5.00',
    image: hamburger,
    alt: 'hamburger.png',
    desc: 'Carne di vitello verdura e salsa'
  }
]

export const piadine = [
  {
    id: "price_1MOR9uFHf4jW5GMcCzeFhxYL",
    product: 'piadina-kebab',
    name: 'Piadina Kebab (Vitello)',
    price: '6.00',
    image: piadinaKebab,
    alt: 'piadinaKebab.png',
    desc: 'Carne di vitello, verdura e salsa'
  },
  {
    id: "price_1MORAlFHf4jW5GMcpUJhUyN0",
    product: 'maxi-piadina-kebab',
    name: 'Maxi Piadina Kebab (Vitello)',
    price: '8.00',
    image: piadinaKebab,
    alt: 'piadinaKebab.png',
    desc: 'Carne di vitello, verdura e salsa'
  },
  {
    id: "price_1MORBXFHf4jW5GMcEaCu6AVx",
    product: 'piadina-tavuk',
    name: 'Piadina Tavuk (Pollo)',
    price: '6.00',
    image: piadinaTavuk,
    alt: 'piadinaTavuk.png',
    desc: 'Carne di pollo, verdura e salsa'
  },
  {
    id: "price_1MORHFFHf4jW5GMctkTpIuCj",
    product: 'maxi-piadina-tavuk',
    name: 'Maxi Piadina Tavuk (Pollo)',
    price: '8.00',
    image: piadinaTavuk,
    alt: 'piadinaTavuk.png',
    desc: 'Carne di pollo, verdura e salsa'
  },
  {
    id: "price_1MORIPFHf4jW5GMcJxxsFlCk",
    product: 'piadina-kebab-e-tavuk',
    name: 'Piadina Misto Kebab-Tavuk',
    price: '6.00',
    image: piadinaTavuk,
    alt: 'piadinaTavuk.png',
    desc: 'Carne di vitello e pollo, verdura e salsa'
  },
  {
    id: "price_1MORJMFHf4jW5GMcT3Js479a",
    product: 'maxi-piadina-kebab-e-tavuk',
    name: 'Maxi Piadina Misto Kebab-Tavuk',
    price: '8.00',
    image: piadinaTavuk,
    alt: 'piadinaTavuk.png',
    desc: 'Carne di vitello e pollo, verdura e salsa'
  },
  {
    id: "price_1O7IjaFHf4jW5GMc057uS4XG",
    product: 'piadina-vegi',
    name: 'Piadina Vegi',
    price: '6.00',
    image: vegiPiadina,
    alt: 'vegiPiadina.png',
    desc: 'Formaggio verdura e salsa'
  },
  {
    id: "price_1MORLCFHf4jW5GMc9gk9k2Yv",
    product: 'maxi-piadina-vegi',
    name: 'Maxi Piadina Vegi',
    price: '7.50',
    image: vegiPiadina,
    alt: 'vegiPiadina.png',
    desc: 'Formaggio verdura e salsa'
  },
  { id: "price_1MORMkFHf4jW5GMcNWnNzloi",
    product: 'piadina-falafel',
    name: 'Piadina Falafel',
    price: '6.00',
    image: piadinaFalafel,
    alt: 'piadinaFalafel.png',
    desc: '4 polpette di ceci con verdura e salsa'
  },
  {
    id: "price_1O7ImGFHf4jW5GMcuaKKMUE6",
    product: 'maxi-piadina-falafel',
    name: 'Maxi Piadina Falafel',
    price: '7.50',
    image: piadinaFalafel,
    alt: 'piadinaFalafel.png',
    desc: '6 polpette di ceci con verdura e salsa'
  },
  {
    id: "price_1MORNtFHf4jW5GMcJeZUAdpI",
    product: 'piadina-hamburger',
    name: 'Piadina Hamburger',
    price: '5.00',
    image: piadinaHamburger,
    alt: 'piadinaHamburger.png',
    desc: 'Carne di vitello verdura e salsa'
  }
]

export const piatti = [
  {
    id: "price_1MORQ7FHf4jW5GMcUFPT7Jng",
    product: 'piatto-kebab',
    name: 'Piatto Kebab (Vitello)',
    price: '8.00',
    image: piattoKebab,
    alt: 'piattoKebab.png',
    desc: 'Carne di vitello, verdura e salsa'
  },
  {
    id: "price_1MORRMFHf4jW5GMcwdSWfLTM",
    product: 'maxi-piatto-kebab',
    name: 'Maxi Piatto Kebab (Vitello)',
    price: '10.00',
    image: piattoKebab,
    alt: 'piattoKebab.png',
    desc: 'Carne di vitello, verdura e salsa'
  },
  {
    id: "price_1MORS9FHf4jW5GMcxxlNJ904",
    product: 'piatto-tavuk',
    name: 'Piatto Tavuk (Pollo)',
    price: '8.00',
    image: piattoTavuk,
    alt: 'piattoTavuk.png',
    desc: 'Carne di pollo, verdura e salsa'
  },
  {
    id: "price_1MORTNFHf4jW5GMcDo4vqKv3",
    product: 'maxi-piatto-tavuk',
    name: 'Maxi Piatto Tavuk (Pollo)',
    price: '10.00',
    image: piattoTavuk,
    alt: 'piattoTavuk.png',
    desc: 'Carne di pollo, verdura e salsa'
  },
  {
    id: "price_1MORUNFHf4jW5GMcMOak800e",
    product: 'piatto-kebab-e-tavuk',
    name: 'Piatto Misto Kebab-Tavuk',
    price: '8.00',
    image: piattoTavuk,
    alt: 'piattoTavuk.png',
    desc: 'Carne  Mista, verdura e salsa'
  },
  {
    id: "price_1MORVVFHf4jW5GMcJ4XPYQmL",
    product: 'maxi-piatto-kebab-e-tavuk',
    name: 'Maxi Piatto Misto Kebab-Tavuk',
    price: '10.00',
    image: piattoTavuk,
    alt: 'piattoTavuk.png',
    desc: 'Carne  Mista, verdura e salsa'
  },
  {
    id: "price_1MORYWFHf4jW5GMcJmKaX47q",
    product: 'piatto-falafel',
    name: 'Piatto Falafel - 5 pezzi',
    price: '6.50',
    image: piattoFalafel,
    alt: 'piattoFalafel.png'
  },
  {
    id: "price_1O7IrBFHf4jW5GMcy8lAQQc0",
    product: 'maxi-piatto-falafel',
    name: 'Piatto Falafel - 8 pezzi',
    price: '8.50',
    image: piattoFalafel,
    alt: 'piattoFalafel.png'
  },
  {
    id: "price_1MORasFHf4jW5GMcUFe6NuIn",
    product: 'box-kebab-tavuk',
    name: 'Box Misto Kebab-Tavuk',
    price: '7.00',
    image: boxKebab,
    alt: 'boxKebab.png'
  },
  {
    id: "price_1MORcaFHf4jW5GMcF1MA8zUa",
    product: 'box-tavuk',
    name: 'Box Tavuk (Pollo)',
    price: '7.00',
    image: boxKebab,
    alt: 'boxKebab.png'
  },
  {
    id: "price_1MORdmFHf4jW5GMcCyZNoQ6j",
    product: 'box-kebab',
    name: 'Box Kebab (Vitello)',
    price: '7.00',
    image: boxKebab,
    alt: 'boxKebab.png'
  }
]

export const menu_vitello = [
  {
    id: "price_1OfTtnFHf4jW5GMcRRKgQSXI",
    product: 'menu-piadina-kebab',
    name: 'Menu Piadina Kebab (Vitello)',
    price: '10.50',
    image: menuPiadina,
    alt: 'menuPiadina.png',
    desc: 'Piadina Kebab + Patatine fritte + Lattina'
  },
  {
    id: "price_1OfTrXFHf4jW5GMcXFT1YPFX",
    product: 'maxi-menu-piadina-kebab',
    name: 'Maxi Menu Piadina Kebab (Vitello)',
    price: '12.50',
    image: menuPiadina,
    alt: 'menuPiadina.png',
    desc: 'Maxi Piadina Kebab + Patatine fritte + Lattina'
  },
  {
    id: "price_1OfTafFHf4jW5GMcmvpVY8e5",
    product: 'menu-panino-kebab',
    name: 'Menu Panino Kebab (Vitello)',
    price: '10.50',
    image: menuPanino,
    alt: 'menuPanino.png',
    desc: 'Panino Kebab + Patatine fritte + Lattina'
  },
  {
    id: "price_1O7Ia0FHf4jW5GMcnUIhoOTV",
    product: 'maxi-menu-panino-kebab',
    name: 'Maxi Menu Panino Kebab (Vitello)',
    price: '12.50',
    image: menuPanino,
    alt: 'menuPanino.png',
    desc: 'Maxi Panino Kebab + Patatine fritte + Lattina'
  }
]

export const menu_pollo = [
  {
    id: "price_1OfTpBFHf4jW5GMcb6xul3XW",
    product: 'menu-piadina-tavuk',
    name: 'Menu Piadina Tavuk (Pollo)',
    price: '10.50',
    image: menuPiadina,
    alt: 'menuPiadina.png',
    desc: 'Piadina Tavuk + Patatine fritte + Lattina'
  },
  {
    id: "price_1OfTn3FHf4jW5GMch0nRCyHB",
    product: 'maxi-menu-piadina-tavuk',
    name: 'Maxi Menu Piadina Tavuk (Pollo)',
    price: '12.50',
    image: menuPiadina,
    alt: 'menuPiadina.png',
    desc: 'Maxi Piadina Tavuk + Patatine fritte + Lattina'
  },
  {
    id: "price_1OfTXOFHf4jW5GMcTioGd9oK",
    product: 'menu-panino-tavuk',
    name: 'Menu Panino Tavuk (Pollo)',
    price: '10.50',
    image: menuPanino,
    alt: 'menuPanino.png',
    desc: 'Panino Tavuk + Patatine fritte + Lattina'
  },
  {
    id: "price_1MOSC5FHf4jW5GMcdP2bmG0z",
    product: 'maxi-menu-panino-tavuk',
    name: 'Maxi Menu Panino Tavuk (Pollo)',
    price: '12.50',
    image: menuPanino,
    alt: 'menuPanino.png',
    desc: 'Maxi Panino Tavuk + Patatine fritte + Lattina'
  }
]

export const menu_tavuk = [
  {
    id: "price_1OfTiCFHf4jW5GMc5ajbv8Py",
    product: 'menu-piadina-kebab-e-tavuk',
    name: 'Menu Piadina Misto Kebab-Tavuk',
    price: '10.50',
    image: menuPiadina,
    alt: 'menuPiadina.png',
    desc: 'Piadina Misto Kebab-Tavuk + Patatine fritte + Lattina'
  },
  {
    id: "price_1OfTg3FHf4jW5GMcezSyTRGj",
    product: 'maxi-menu-piadina-kebab-e-tavuk',
    name: 'Maxi Menu Piadina Misto Kebab-Tavuk',
    price: '12.50',
    image: menuPiadina,
    alt: 'menuPiadina.png',
    desc: 'Maxi Piadina Misto Kebab-Tavuk + Patatine fritte + Lattina'
  },
  {
    id: "price_1OfTVCFHf4jW5GMcti0orQnw",
    product: 'menu-panino-kebab-e-tavuk',
    name: 'Menu Panino Misto Kebab-Tavuk ',
    price: '10.50',
    image: menuPanino,
    alt: 'menuPanino.png',
    desc: 'Panino Misto Kebab-Tavuk + Patatine fritte + Lattina'
  },
  {
    id: "price_1OfTOaFHf4jW5GMcbLpoNRRF",
    product: 'maxi-menu-panino-kebab-e-tavuk',
    name: 'Maxi Menu Misto Panino Kebab-Tavuk ',
    price: '12.50',
    image: menuPanino,
    alt: 'menuPanino.png',
    desc: 'Maxi Panino Kebab-Tavuk + Patatine fritte + Lattina'
  }
]

export const contorni = [
  {
    id: "price_1OfUOJFHf4jW5GMcZIVoCjca",
    product: 'patatine-normali',
    name: 'Patatine fritte - Normali',
    price: '3.00',
    image: patatine,
    alt: 'patatine.png'
  },
  {
    id: "price_1OfUN3FHf4jW5GMcxVx8O4Wu",
    product: 'patatine-maxi',
    name: 'Patatine fritte - Maxi',
    price: '6.00',
    image: patatine,
    alt: 'patatine.png'
  },
  {
    id: "price_1NEs2HFHf4jW5GMce1kfNmgI",
    product: 'anelli di cipolla',
    name: 'Anelli di Cipolla Fritti 5pz ',
    price: '3.00',
    image: anelli,
    alt: 'anelli.png'
  },
  {
    id: "price_1NEs3YFHf4jW5GMclPKC74DG",
    product: 'anelli di cipola',
    name: 'Anelli di Cipolla Fritti 8pz ',
    price: '5.00',
    image: anelli,
    alt: 'anelli.png'
  },
  {
    id: "price_1NEs4fFHf4jW5GMcwYuVkJrD",
    product: 'mozzarelline impanate',
    name: 'Mozzarelline Impanate 5pz ',
    price: '3.00',
    image: mozzarella,
    alt: 'mozzarella.png'
  },
  {
    id: "price_1NEs5eFHf4jW5GMcXtlQTFoD",
    product: 'mozzarelline impanat',
    name: 'Mozzarelline Impanate 8pz ',
    price: '5.00',
    image: mozzarella,
    alt: 'mozzarella.png'
  },
  {
    id: "price_1O70SWFHf4jW5GMcVYEUnGXW",
    product: 'chicken-nuggets-e-patate-fritte',
    name: 'Chicken Nuggets + Patate Fritte',
    price: '7.50',
    image: nuggetspatate,
    alt: 'nuggetspatate.png'
  },
  {
    id: "price_1O7J1JFHf4jW5GMcRVYkgJlL",
    product: 'chicken-nuggets-3',
    name: 'Chicken Nuggets - Porzione da 3',
    price: '2.50',
    image: nuggetsPollo,
    alt: 'nuggetsPollo.png'
  },
  {
    id: "price_1MOSJIFHf4jW5GMcHs1qsHbK",
    product: 'chicken-nuggets-6',
    name: 'Chicken Nuggets - Porzione da 6',
    price: '4.00',
    image: nuggetsPollo,
    alt: 'nuggetsPollo.png'
  },
  {
    id: "price_1MOSJzFHf4jW5GMcRrbvkyw8",
    product: 'chicken-nuggets-10',
    name: 'Chicken Nuggets - Porzione da 10',
    price: '6.00',
    image: nuggetsPollo,
    alt: 'nuggetsPollo.png'
  },
  {
    id: "price_1OfAZXFHf4jW5GMcFA2AcxMH",
    product: 'vaschetta-solo-carne Kebab',
    name: 'Vaschetta solo carne KEBAB',
    price: '3.50',
    image: vaschettaCarne,
    alt: 'vaschettaCarne.png'
  },
  {
    id: "price_1MOSNdFHf4jW5GMcDcFex9Bc",
    product: 'vaschetta-solo-carne',
    name: 'Vaschetta solo carne TAVUK',
    price: '3.50',
    image: vaschettaCarne,
    alt: 'vaschettaCarne.png'
  },
  
  {
    id: "price_1MOSPRFHf4jW5GMcgTJmS1Z2",
    product: 'falafel-3',
    name: 'Falafel - Porzione da 3',
    price: '3.00',
    image: falafel,
    alt: 'falafel.png'
  },
  {
    id: "price_1OfTHSFHf4jW5GMccJ0w6EBR",
    product: 'falafel-6',
    name: 'Falafel - Porzione da 6',
    price: '5.00',
    image: falafel,
    alt: 'falafel.png'
  },
  {
    id: "price_1MOSRQFHf4jW5GMcImSpcJhd",
    product: 'falafel-10',
    name: 'Falafel - Porzione da 10',
    price: '8.00',
    image: falafel,
    alt: 'falafel.png'
  },

  {
    id: "price_1MOSSBFHf4jW5GMcDv9tY2nI",
    product: 'ketchup',
    name: 'Ketchup',
    price: '0.20',
    image: ketchup,
    alt: 'ketchup.png'
  },
  {
    id: "price_1MOSSxFHf4jW5GMcCZTVKRau",
    product: 'maionese',
    name: 'Maionese',
    price: '0.20',
    image: maionese,
    alt: 'maionese.png'
  },
  {
    id: "price_1MOSTaFHf4jW5GMc0OG2M3OE",
    product: 'salsa-piccante',
    name: 'Salsa Piccante',
    price: '0.20',
    image: salsaPiccante,
    alt: 'salsaPiccante.png'
  },
  {
    id: "price_1MOSUbFHf4jW5GMcq5TZLQHO",
    product: 'salsa-peperoni',
    name: 'Salsa Yogurt ai Peperoni',
    price: '0.50',
    image: salsaPeperoni,
    alt: 'salsaPeperoni.png'
  },
  {
    id: "price_1MOSW1FHf4jW5GMcQWl4nlAr",
    product: 'salsa-aglio',
    name: "Salsa Yogurt all'aglio",
    price: '0.50',
    image: salsaAglio,
    alt: 'salsaAglio.png'
  }
]

export const cevapcici = [
  {
    id: "price_1O73VgFHf4jW5GMc4EVLEKS2",
    product: 'cevapcici-10',
    name: 'Cevapcici - Porzione da 10',
    price: '7.00',
    image: cevapciciP,
    alt: 'cevapciciP.png'
  },
  {
    id: "price_1MOSXzFHf4jW5GMcooDRhwso",
    product: 'cevapcici-15',
    name: 'Cevapcici - Porzione da 15',
    price: '9.00',
    image: cevapciciP,
    alt: 'cevapciciP.png'
  }
]

export const bibite = [
  {
    id: "price_1MOSYoFHf4jW5GMcAnbO8MuN",
    product: 'acqua-naturale-05ml',
    name: 'Acqua Naturale 0.5ml',
    price: '1.00',
    image: acqua,
    alt: 'acqua.png'
  },
  {
    id: "price_1MOSZWFHf4jW5GMcOH0XcaJw",
    product: 'acqua-frizzante-05ml',
    name: 'Acqua Frizzante 0.5ml',
    price: '1.00',
    image: acqua,
    alt: 'acqua.png'
  },
  
  {
    id: "price_1OfT7zFHf4jW5GMct7tswMzC",
    product: 'redbull',
    name: 'RedBull',
    price: '3.00',
    image: redbull,
    alt: 'redbull.png'
  },
  {
    id: "price_1MOSb6FHf4jW5GMcNcc8lbZz",
    product: 'golden',
    name: 'Golden Eagle',
    price: '2.00',
    image: golden,
    alt: 'golden.png'
  },
  {
    id: "price_1OfTEDFHf4jW5GMcJSqsuf72",
    product: 'fanta',
    name: 'Fanta',
    price: '2.00',
    image: fanta,
    alt: 'fanta.png'
  },
  {
    id: "price_1OfTCmFHf4jW5GMcDhuCzhww",
    product: 'coca-cola',
    name: 'Coca Cola',
    price: '2.00',
    image: coca,
    alt: 'coca.png'
  },
  {
    id: "price_1OfT9VFHf4jW5GMcE2MdWkU1",
    product: 'coca-cola-zero',
    name: 'Coca Cola Zero',
    price: '2.00',
    image: cocaZero,
    alt: 'cocaZero.png'
  },


  {
    id: "price_1OfT5TFHf4jW5GMcNDkJIOup",
    product: 'pepsi',
    name: 'Pepsi',
    price: '2.00',
    image: pepsi,
    alt: 'pepsi.png'
  },

  {
    id: "price_1OfT4CFHf4jW5GMcqY0eqlov",
    product: 'sprite',
    name: 'Sprite',
    price: '2.00',
    image: sprite,
    alt: 'sprite.png'
  },
  {
    id: "price_1MOSgoFHf4jW5GMckP9ZSEJb",
    product: 'lemon',
    name: 'Lemon',
    price: '2.00',
    image: lemon,
    alt: 'lemon.png'
  },
  {
    id: "price_1OfT1SFHf4jW5GMcBXma9W0S",
    product: 'tonica',
    name: 'Tonica',
    price: '2.00',
    image: tonica,
    alt: 'tonica.png'
  },
  {
    id: "price_1OfSyEFHf4jW5GMcs3NlfU3t",
    product: 'te-pesca',
    name: 'Tè Pesca',
    price: '2.00',
    image: tePesca,
    alt: 'tePesca.png'
  },
  
  {
    id: "price_1OfSuxFHf4jW5GMcolHw8AvE",
    product: 'te-limone',
    name: 'Tè Limone',
    price: '2.00',
    image: teLimone,
    alt: 'teLimone.png'
  },
  
  {
    id: "price_1MOSl2FHf4jW5GMcxfoAb7x5",
    product: 'ayran',
    name: 'Ayran (Yogurt)',
    price: '1.00',
    image: ayran,
    alt: 'ayran.png'
  }
]

export const contorniMenu = [
  {
    id: "",
    product: "patate-fritte",
    name: "Patate Fritte",
    image: patatine,
    alt: "patatine.png"
  },
  {
    id: "",
    product: "chicken-nuggets",
    name: "Chicken Nuggets",
    image: nuggetsPollo,
    alt: "nuggets.png"
  }
]

export const altro = [
  {
    id: "price_1O7lzZFHf4jW5GMcbnZFvdtW",
    product: 'Baklava',
    name: 'Baklava ' ,
    price: '1.50',
    image: amarena,
    alt: 'amarena.png',
    desc: ''
  }

]