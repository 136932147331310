import { React, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { SingleType1 } from "../components/SingleType1"
import "./../css/singleProduct.css"
import { ingredients } from "../components/IngredientsList"
import { productInfo } from "../components/SingleType1"
import { extra } from "../components/ExtraIngredientsList"
import { genearteKey, i } from "../javascript/localStorageFunctions"
import { tipo, prodottoType, hasKetchup, hasMaionese } from "../components/SingleType1"
import { sDrink, SelectDrinks } from "../components/SelectDrinks"
import { normalOrder, altroOrder, bibitaOrder, chickenPatateOrder, contorniOrder, menuOrder, patatineOrder } from "../javascript/dataModeling"
import { resetDrink } from "../components/SelectDrinks"
import IdleTimerContainer from "../components/IdleTimerContainer"
import { ArrowLeftOutlined, ShoppingCartOutlined } from "@ant-design/icons"
import { sContorno } from "../components/Contorni"

export const SingleProduct = () => {
  const [amount, setAmount] = useState(1);
  let { type } = useParams();
  let isMenu, isNotMenu;

  function decreaseAmount() {
    setAmount(amount - 1)
    if (amount < 2) {
      setAmount(1)
    }
  }

  function addToCart() {
    if (prodottoType === "hot-dog") {
      let order = chickenPatateOrder(i, tipo, productInfo, amount, hasKetchup, hasMaionese)
      localStorage.setItem(i, JSON.stringify(order));
    }
    else if (tipo === "panini" || tipo === "piadine" || tipo === "piatti" || tipo === "cevapcici") {
      let order = normalOrder(i, tipo, productInfo, ingredients, extra, amount)
      localStorage.setItem(i, JSON.stringify(order));
    }
    else if (tipo === "bibite") {
      let order = bibitaOrder(i, tipo, productInfo, amount)
      localStorage.setItem(i, JSON.stringify(order));
    }
    else if (tipo === "pollo" || tipo === "misto" || tipo === "vitello") {
      let order = menuOrder(i, 'menu', productInfo, ingredients, extra, sDrink, sContorno, amount)
      localStorage.setItem(i, JSON.stringify(order));
    }
    else if (prodottoType === "chicken-nuggets-e-patate-fritte") {
      let order = chickenPatateOrder(i, tipo, productInfo, amount, hasKetchup, hasMaionese)
      localStorage.setItem(i, JSON.stringify(order));
    }
    else if (tipo === "altro") {
      let order = altroOrder(i, tipo, productInfo, amount)
      localStorage.setItem(i, JSON.stringify(order));
    }
    else if (prodottoType === "patatine-normali" || prodottoType === "patatine-maxi") {
      let order = patatineOrder(i, tipo, productInfo, amount, hasKetchup, hasMaionese)
      localStorage.setItem(i, JSON.stringify(order));
    } else if (tipo === "contorni") {
      let order = contorniOrder(i, tipo, productInfo, amount)
      localStorage.setItem(i, JSON.stringify(order));
    }
  }

  if (type === "vitello" || type === "pollo" || type === "misto") {
    isNotMenu = { display: 'none' }
  } else {
    isMenu = { display: 'none' }
  }

  return (
    <div className="bg">
      <div className="top">
        <div className="left">
          {/* <IdleTimerContainer/> */}
          <Link to={"/order/" + type} className="text-decoration__none">
            <ArrowLeftOutlined className="icon-size" />
          </Link>
        </div>
        <div className="right">
          <Link to="/cart" className="text-decoration__none">
            <ShoppingCartOutlined className="icon-size" />
          </Link>
        </div>
      </div>
      <SingleType1 />
      <div className="container-single">
        <div className="amount-box-single">
          <button className="value-button-single" onClick={decreaseAmount} id="decrease">-</button>
          <div className="amount-single">{amount}</div>
          <button className="value-button-single" onClick={() => setAmount(amount + 1)} id="increase">+</button>
        </div>
        <div style={isMenu}>
          <SelectDrinks add={addToCart} />
        </div>
        <div style={isNotMenu}>
          <Link to="/order" className="text-decoration__none">
            <button className="addcart-single" onClick={() => { addToCart(); genearteKey(); resetDrink() }}>AGGIUNGI AL CARRELLO</button>
          </Link>
        </div>
      </div>
    </div>
  )
}