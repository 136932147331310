import { React } from "react"
import { useParams } from "react-router-dom"
import { SpecificProduct } from "./SpecificProduct"
import { panini, piadine, piatti, contorni, cevapcici, bibite, altro, menu_vitello, menu_pollo, menu_tavuk } from "./../javascript/data"

export const SpecificProductList = () => {

  let { type } = useParams();
  let product, css;

  if (type === "panini") {
    product = panini
    css = "position"
  } else if (type === "piadine") {
    product = piadine
    css = "position"
  } else if (type === "piatti") {
    product = piatti
    css = "position"
  } else if (type === "contorni") {
    product = contorni
    css = "position"
  } else if (type === "cevapcici") {
    product = cevapcici
    css = "position"
  } else if (type === "bibite") {
    product = bibite
    css = "position"
  } else if (type === "altro") {
    product = altro
    css = "position"
  } else if (type === "vitello") {
    product = menu_vitello
    css = "position-2"
  } else if (type === "pollo") {
    product = menu_pollo
    css = "position-2"
  } else if (type === "misto") {
    product = menu_tavuk
    css = "position-2"
  }

  return (
    <div className={ css }>
      { product.map((item, index) => (
        <SpecificProduct item={ item } key={ index } />
      ))}
    </div>
  )
}